<template>
  <div class="admin-container">
    <template v-if="data">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Timeline' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="timeline.name"
            label="Název etapy *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <!-- <v-text-field
            v-model="timeline.supposed_term_of_completion"
            label="Předpokládaný termín provedení *"
            placeholder="D.M.R"
          ></v-text-field
        > -->
          <DatePicker
            label="Předpokládaný termín zahájení *"
            @dateChanged="changeDateBegin($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- label="Vložit za etapu" -->
        <!-- item-text="name"
            item-value="id" -->
        <v-col cols="12" md="3">
          <v-select
            :items="data"
            v-model="timeline.behind_timeline"
            label="Pořadí etapy"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <DatePicker
            label="Předpokládaný termín dokončení *"
            @dateChanged="changeDate($event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6"
          ><v-textarea
            v-model="timeline.note"
            label="Poznámka"
            auto-grow
          ></v-textarea
        ></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Timeline' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="store">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import timelineService from '../api/timelineService';
import permissionDenied from '../api/permissionDenied';
import DatePicker from '../components/DatePicker';
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      data: [],
      timeline: {},
    };
  },
  async created() {
    bus.$emit('processing');
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    await this.getTimeline();
    bus.$emit('processing', false);
  },
  methods: {
    changeDate(v) {
      this.timeline.supposed_term_of_completion = v;
    },

    changeDateBegin(v) {
      this.timeline.supposed_term_of_begin = v;
    },

    async getTimeline() {
      try {
        const data = await timelineService.index();
        for (let i = 0; i < data.length; i++) {
          this.data.push(i + 1);
        }
        this.data.push(this.data.length + 1);
        this.timeline.behind_timeline = this.data[this.data.length - 1];
        // this.data = data;
        // if (data.length) {
        //   let sortedData = data.sort((a, b) => a.id - b.id);
        //   this.timeline.behind_timeline =
        //     sortedData[sortedData.length - 1]["id"];
        // }
      } catch (error) {
        console.error(error);
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        const response = await timelineService.store(this.timeline);
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({ name: 'Timeline' });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    timeline() {
      console.debug(this.timeline);
    },
  },
};
</script>

<style></style>
